import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client'
import { FC } from 'react'
import '../styles/global.css'

const client = new ApolloClient({
  uri: process.env.GATSBY_API_URL,
  cache: new InMemoryCache(),
  headers: {
    Authorization: `Bearer ${process.env.GATSBY_CONTENT_DELIVERY_TOKEN}`,
  },
})

const App: FC = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)

export default App
