exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-fotky-deti-tsx": () => import("./../../../src/pages/fotky-deti/[...].tsx" /* webpackChunkName: "component---src-pages-fotky-deti-tsx" */),
  "component---src-pages-fotky-tsx": () => import("./../../../src/pages/fotky/[...].tsx" /* webpackChunkName: "component---src-pages-fotky-tsx" */),
  "component---src-pages-home-tsx": () => import("./../../../src/pages/Home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jsdh-tsx": () => import("./../../../src/pages/jsdh.tsx" /* webpackChunkName: "component---src-pages-jsdh-tsx" */),
  "component---src-pages-kalendar-tsx": () => import("./../../../src/pages/kalendar.tsx" /* webpackChunkName: "component---src-pages-kalendar-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-novinky-tsx": () => import("./../../../src/pages/novinky.tsx" /* webpackChunkName: "component---src-pages-novinky-tsx" */),
  "component---src-pages-sdh-tsx": () => import("./../../../src/pages/sdh.tsx" /* webpackChunkName: "component---src-pages-sdh-tsx" */),
  "component---src-pages-vyjezdy-tsx": () => import("./../../../src/pages/vyjezdy.tsx" /* webpackChunkName: "component---src-pages-vyjezdy-tsx" */)
}

